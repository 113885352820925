import React from 'react';
import { Link } from 'react-router-dom';

export default function Footer() {
    return (
        <footer className="rmc-bg-secondary-2 px-6 pt-6 pb-4 max-lg:p-0">
            <div className={'flex flex-col gap-2 p-8 rmc-bg-base-1 rounded-[30px] max-lg:rounded-none max-md:flex-wrap divide-y divide-neutral-700'}>
                <div className="grid grid-cols-2 sm:grid-cols-4 gap-4 sm:gap-2 max-md:w-full max-md:justify-around items-start max-md:mb-4">
                    <div className={'flex flex-col w-1/3 gap-2 max-md:w-full max-md:justify-around items-start max-md:mb-4'}>
                        <Link to={'/'}>
                            <img loading="lazy" src={'/Logo_Uniqrate-White.svg'} alt={'Uniqrate logo'} className={'h-[32px]'} />
                        </Link>
                        <p className={'text-sm rmc-text-base-2 leading-5'}>Rate, Comment, Engage</p>
                    </div>
                    <div className={'flex flex-col gap-2 max-md:w-[45%] max-md:mr-4'}>
                        <p className={'rmc-text-base-2 leading-[25.6px] font-medium'}>PRODUCT</p>
                        <Link to={'/'} className="font-medium leading-[25.6px] text-white hover:rmc-text-primary-2">
                            All-in-one analytics tool
                        </Link>
                        <Link to={'/bloggers'} className="font-medium leading-[25.6px] text-white hover:rmc-text-primary-2">
                            For Bloggers
                        </Link>
                        <Link to={'/technical-writers'} className="font-medium leading-[25.6px] text-white hover:rmc-text-primary-2">
                            For Technical Writers
                        </Link>
                        {/* <Link to={''} className="font-medium leading-[25.6px] text-white hover:rmc-text-primary-2">
                            AI Content Writers
                        </Link> */}
                        <Link to={'/pricing'} className="font-medium leading-[25.6px] text-white hover:rmc-text-primary-2">
                            Pricing
                        </Link>
                    </div>
                    <div className={'flex flex-col gap-2 max-md:w-[45%]'}>
                        <p className={'rmc-text-base-2 leading-[25.6px] font-medium'}>COMPANY</p>
                        <Link to={'/blog'} className="font-medium leading-[25.6px] text-white hover:rmc-text-primary-2">
                            Blog
                        </Link>
                        <Link
                            target={'_blank'}
                            className="font-medium leading-[25.6px] text-white hover:rmc-text-primary-2"
                            to={'https://climate.stripe.com/icSMy1'}
                        >
                            Fight for the Climate
                        </Link>
                    </div>
                    <div className={'flex flex-col gap-2 max-md:w-[45%]'}>
                        <Link
                            to={'https://notifizz.com/'}
                            target={'_blank'}
                            className="font-medium leading-[25.6px] text-white hover:rmc-text-primary-2"
                        >
                            Notifizz
                        </Link>
                        <Link
                            to={'https://haven.markets/'}
                            target={'_blank'}
                            className="font-medium leading-[25.6px] text-white hover:rmc-text-primary-2"
                        >
                            Haven.markets
                        </Link>
                    </div>
                </div>
                <div className='mt-6 pt-6 text-white flex flex-col sm:flex-row gap-6 border-top'>
                    <p className='text-sm rmc-text-base-2'>2024 uniqrate. All rights reserved.</p>
                    {/* <Link to={'terms-of-use'} className="text-sm underline hover:rmc-text-primary-2">
                        Terms of Service
                    </Link>
                    <Link to={'privacy-policy'} className="text-sm underline hover:rmc-text-primary-2">
                        Privacy Policy
                    </Link> */}
                </div>
            </div>
        </footer>
    );
}
