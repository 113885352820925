import React from 'react';
import Highlighter from './components/highlighter';
import { Link } from 'react-router-dom';

export default function HeroTechnical() {
    const signupUrl = process.env.REACT_APP_SIGNUP_URL as string;
    return (
        <div className='rmc-bg-primary-4 pt-16 md:pt-48'>
            <div className="mx-auto max-w-[1240px] px-8 lg:px-0 grid grid-cols-2 gap-6 md:gap-20">
                <div className='col-span-2 md:col-span-1'>
                    <h1 className="tracking-normal text-left text-5xl md:text-6xl font-extrabold line-height-[61px] rmc-text-base-1">
                        <Highlighter colored={true}>Master <span className='rmc-text-primary-1'>content accuracy</span></Highlighter>
                    </h1>
                </div>
                <div className='flex flex-col place-items-start col-span-2 md:col-span-1'>
                    <p className="text-base md:text-xl rmc-text-base-1 max-w-2xl m-auto flex flex-col gap-2 leading-6 md:leading-8 font-normal">
                        As a technical writer, you know that accurate, clear documentation is crucial—but how do you know it’s truly hitting the mark? Uniqrate empowers you to assess content accuracy by providing real-time feedback from your audience.
                    </p>
                    <Link
                        to={signupUrl}
                        className="mt-6 font-bold text-xl py-4 px-8 rmc-bg-primary-1 text-white hover:rmc-bg-primary-3 rounded-lg max-md:w-full text-center md:text-left"
                        >
                        ⚡️ Challenge your content's accuracy
                    </Link>
                    <p className='mt-2 text-sm'>
                        14-day free trial
                    </p>
                    <div className='mt-6 flex flex-row gap-3 w-full'>
                        <div className='flex flex-col'>
                            <div className='flex flex-row gap-2'>
                                <img
                                    loading="lazy"
                                    src={'../Stars.svg'}
                                    alt={'Ratings on island black theme'}
                                    className={'h-[16px] w-[96px]'}
                                />
                                <p className='text-xs font-semibold'>5.0</p>
                            </div>
                            <p className='mt-2 text-xs'>
                                Trusted by DamCo, Indie Makers
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}