import React from 'react';
import Highlighter from './components/highlighter';
import { Link } from 'react-router-dom';

export default function Hero() {
    const signupUrl = process.env.REACT_APP_SIGNUP_URL as string;
    return (
        <div className="relative isolate overflow-hidden rmc-bg-primary-4">
            <div className="mx-auto max-w-[1240px] px-6 lg:px-8 mt-16 sm:mt-32 lg:mt-24">
                <div className="mx-auto max-w-4xl">
                    <h1 className="mt-34 tracking-normal text-center text-5xl md:text-6xl font-extrabold line-height-[61px] rmc-text-base-1">
                        <Highlighter colored={true}>Analytics and reader feedback for <span className='rmc-text-primary-1'>blogs and docs</span></Highlighter>
                    </h1>
                    <p className="mt-6 text-base md:text-xl rmc-text-base-1 max-w-2xl m-auto text-center flex flex-col gap-2 leading-6 md:leading-8">
                            <Highlighter colored={false}>
                                Combine traditional analytics with reader feedback. Understand what your readers love,
                                improve content quality, and boost engagement.
                            </Highlighter>
                    </p>
                    <div className="mt-10 items-center font-medium text-center text-lg w-fit m-auto gap-6 flex max-md:flex-col-reverse max-md:w-full">
                        {/* <button className="py-4 px-8 rounded-lg border rmc-border-neutral-2 hover:rmc-bg-secondary-3 max-md:w-full">
                            View Demo
                        </button>*/}
                        <Link
                            to={signupUrl}
                            className="font-bold text-xl py-4 px-8 rmc-bg-primary-1 text-white hover:rmc-bg-primary-3 rounded-lg max-md:w-full text-center md:text-left"
                        >
                            ⚡ Get Uniqrate
                        </Link>
                    </div>
                    <div className={'m-auto text-center mt-2 text-[13px]'}>14-day free trial</div>
                    <div className='mt-10 flex flex-col gap-2 justify-center'>
                        <div className='mt-2 flex flex-row gap-2 justify-center'>
                            <img
                                loading="lazy"
                                src={'/Stars.svg'}
                                alt={'Ratings on island black theme'}
                                className={'h-[16px] w-[96px]'}
                            />
                            <p className='text-xs font-semibold'>5.0</p>
                        </div>
                        <p className='text-xs text-center'>
                            Trusted by DamCo, Indie Makers
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
